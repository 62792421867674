<template>
  <div>
    <main class="site-main" v-html="siteMain" @click="check($event)"/>
    <div class="modal" v-if="showModal">
      <!-- 사이트 이탈 Modal의 내용 -->
      <div class="modal_contents">
        <div class="modal_close close">
          <a @click="closeModal">close</a>
        </div>
        <p class="modal_tit">현재 보고 계신<br> <strong>BMSON</strong> 웹사이트를 나갑니다.</p>
        <p class="modal_exp">현재 웹사이트를 나와 다른 외부 웹사이트와 연결됩니다. BMSON은 외부 사이트의 콘텐츠에 대한 책임을 지지 않으며, 해당 콘텐츠로 인한 손해나 손상에
          대한 법적 책임이 없습니다. 외부 사이트 링크는 단지 웹사이트 사용자들에게 편의를 제공하기 위함입니다.</p>
        <div class="modal_btn">
          <span class="btn_modal" @click="closeModal({url: url})">계속하기</span>
          <span class="btn_modal" @click="closeModal">취소</span>
        </div>
      </div>
    </div>
    <div class="modal" v-if="showModalVideo" @click="closeVideoModal">
      <!-- 사이트 이탈 Modal의 내용 -->
      <div class="modal_contents video" >
        <div class="modal_close close">
          <a @click="closeVideoModal">close</a>
        </div>
        <iframe
            :src="videoUrl"
            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
            style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="videoTitle"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "index",
  data() {
    return {
      url: '',
      showModal: false,
      showModalVideo: false,
      videoUrl: '',
      videoTitle : '',
    }
  },
  computed: {
    ...mapGetters({siteMain: 'getInterestTherapeuticAreaMainVisual'}),
  },
  created() {
    this.myInterestTherapeuticAreaMainVisual()
  },
  methods: {
    ...mapActions(['myInterestTherapeuticAreaMainVisual']),
    check(event) {
      let {target} = event
      this.findATag(target, event)
    },
    findATag(element, event) {
      if (!element.parentNode) {
        event.preventDefault()
        return false;
      } else if (element.tagName.toLowerCase() !== 'a' && element.tagName.toLowerCase() !== 'button') {
        return this.findATag(element.parentNode, event)
      } else {
        let {href, origin: targetOrigin } = element
        let {title, type, url} = element.dataset
        if(type === 'video'){
          this.videoUrl = url
          this.videoTitle = title
          this.openVideoModal()
        } else {
          let {origin: locationOrigin} = location
          if (href) {
            let expUrl = /^http[s]?:\/\//i;
            if (expUrl.test(href)) {
              if (targetOrigin !== locationOrigin) {
                this.openExternalModal(element.href)
              } else {
                return true;
              }
            }
          }
        }

        event.preventDefault()
      }
    },
    openExternalModal(url) {
      this.url = url
      this.showModal = true;
      document.documentElement.classList.add("overflow-y-hidden");
    },
    openVideoModal() {
      this.showModalVideo = true;
      document.documentElement.classList.add("overflow-y-hidden");
    },
    closeVideoModal() {
      this.showModalVideo = false;
      document.documentElement.classList.remove("overflow-y-hidden");
    },
    closeModal({url}) {
      if (url) {
        window.open(url, '_blank')
      }
      document.documentElement.classList.remove("overflow-y-hidden");
      this.showModal = false;
    },
  },
}
</script>

<style scoped>
</style>
